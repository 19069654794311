<script setup lang="ts">
import { totalHeaderHeight } from '~/src/constants'
// Todo: make spacing more generic
const props = withDefaults(defineProps<{ spacing?: number; newSpacing?: string }>(), {
  spacing: 0,
  newSpacing: '',
})

const header = props.newSpacing || `${totalHeaderHeight + props.spacing}px`
</script>
<template>
  <div class="layout-content">
    <slot />
  </div>
</template>
<style>
.layout-content {
  @apply relative overflow-scroll;
  height: calc(100vh - v-bind(header));
}
</style>
